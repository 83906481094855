<template>
  <div class="py-4 py-lg-6 bg-primary">
    <div class="container">
      <div class="row">
        <div class="offset-lg-1 col-lg-10 col-md-12 col-12">
          <div class="d-lg-flex align-items-center justify-content-between">
            <!-- Content -->
            <div class="mb-4 mb-lg-0">
              <h1 class="text-white mb-1">Thêm trường học</h1>
              <p class="mb-0 text-white lead">
                Điền thông tin rồi ấn Thêm mới để thêm trường học
              </p>
            </div>
            <div>
              <router-link class="btn btn-white" :to="{name: listRoute, query: listRouteQuery}">
                Danh sách trường học
              </router-link>&nbsp;
              <button type="button" v-on:click="onSubmit"
                      class="btn btn-success ">Thêm
                mới
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pb-12">
    <div class="container">
      <div id="schoolForm" class="bs-stepper">
        <div class="row">
          <div class="offset-lg-1 col-lg-10 col-md-12 col-12">
            <div class="bs-stepper-content mt-5">
              <form v-on:submit.prevent="">
                <div class="card mb-3 ">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="mb-3">
                          <label for="name" class="form-label">Tên trường</label>
                          <input v-model="school.name" id="name" class="form-control" type="text" required/>
                        </div>
                        <div class="mb-3">
                          <label for="subdomain" class="form-label">Sub domain</label> <span class="float-end"><span
                            class="badge bg-primary">{{
                            school.subdomain !== '' ? school.subdomain : 'subdomain'
                          }}</span>.5seschool.vn</span>
                          <input v-model="school.subdomain" id="subdomain" class="form-control" type="text" required/>
                        </div>
                        <div class="mb-3">
                          <label class="form-label">Giới thiệu</label>
                          <Editor v-model:content="school.content"/>
                        </div>
                        <div class="mb-3 mt-3">
                          <div class="d-inline-flex">
                            <div class="form-check me-3">
                              <input type="radio" id="statusActive" v-model="school.status" value=1
                                     class="form-check-input">
                              <label class="form-check-label" for="statusActive">Hoạt động</label>
                            </div>
                            <div class="form-check">
                              <input type="radio" id="statusInactive" v-model="school.status" value=0
                                     class="form-check-input">
                              <label class="form-check-label" for="statusInactive">Tạm khóa</label>
                            </div>
                          </div>
                        </div>
                        <div class="mb-3">
                          <label for="adminName" class="form-label">Tên admin</label>
                          <input v-model="school.adminName" id="adminName" class="form-control" type="text" required/>
                        </div>
                        <div class="mb-3">
                          <label for="adminEmail" class="form-label">Email admin</label>
                          <input v-model="school.adminEmail" id="adminEmail" class="form-control" type="email"
                                 required/>
                        </div>
                        <div class="mb-3">
                          <label for="adminPassword" class="form-label">Password admin</label>
                          <input v-model="school.adminPassword" id="adminPassword" class="form-control" type="text"
                                 required/>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <Thumbnail v-on:setThumbnail="handleSetThumbnailEvent" :thumbnail="thumbnail"/>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Media :mediaMode="mediaMode" v-on:setMedia="handleSetMediaEvent"/>
</template>

<script>
import Media from "@/components/Media";
import Thumbnail from "@/components/Thumbnail";
import Editor from "@/components/Editor";

import ApiService from "@/core/services/api.service";
import {isPageAdmin, isPageAgency} from "@/core/services/utils.service";

export default {
  name: 'AddSchool',
  components: {
    Media,
    Thumbnail,
    Editor
  },
  data() {
    return {
      listRoute: 'AdminListSchool',
      listRouteQuery: {},
      school: {
        name: "",
        subdomain: "",
        content: "",
        status: 1,
        thumbnail_id: null,
        adminName: "",
        adminEmail: "",
        adminPassword: ""
      },
      mediaMode: "thumbnail",
      thumbnail: null
    }
  },
  methods: {
    handleSetThumbnailEvent() {
      this.mediaMode = "thumbnail";
    },
    handleSetMediaEvent(media) {
      this.thumbnail = media;
      this.school.thumbnail_id = parseInt(media.id);
    },
    onSubmit() {
      let query = `mutation($name: String!, $subdomain: String!, $content: String, $status: Int, $thumbnail_id: Int, $adminName: String, $adminEmail: String!, $adminPassword: String) {
        initSchool(
          name: $name,
          subdomain: $subdomain,
          content: $content,
          status: $status,
          thumbnail_id: $thumbnail_id,
          adminName: $adminName,
          adminEmail: $adminEmail,
          adminPassword: $adminPassword) {
            id
        }
      }`;

      ApiService.graphql(query, this.school)
          .then(({data}) => {
            if (data.data && data.data.initSchool) {
              this.$router.push({name: this.listRoute, query: this.listRouteQuery})
            } else {
              alert("Thêm thất bại");
            }
          })
          .catch(({response}) => {
            console.log(response);
            alert("Thêm thất bại");
          });
    }
  },
  mounted() {
    if (isPageAdmin()) {
      this.listRoute = "AdminListSchool";
    }
    if (isPageAgency()) {
      // this.listRoute = "MyAgency";
      // this.listRouteQuery = {tab: "school"};
    }
  }
}
</script>

<style>
.editor {
  height: 200px;
}
</style>
